<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item"><a :class="{'active': tab == '#open'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#open">Questionnaire (Open)</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#closed'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#closed">Questionnaire (Closed)</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#rating'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#rating">Rating Scale</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#interview'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#interview">Interview Guide</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" v-if="tab == '#open'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Questionnaire (Open)</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'ReflectionOpenCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <open></open>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#closed'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Questionnaire (Closed)</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'ReflectionClosedCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <closed></closed>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#rating'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Rating Scale</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'ReflectionRatingCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <rating></rating>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#interview'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Interview Guide</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'ReflectionInterviewCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <interview></interview>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Open from './Sub/Open/Open.vue'
import Closed from './Sub/Closed/Closed.vue'
import Rating from './Sub/Rating/Rating.vue'
import Interview from './Sub/Interview/Interview.vue'
export default {
    components: {
        Open,
        Closed,
        Rating,
        Interview,
    },
    mounted() {
        if (this.$route.hash) {
            this.tab = this.$route.hash
        }
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            tab: '#open'
        }
    },
}
</script>
